import { gsap } from "gsap";

export const header = () => {
    const header = document.querySelector(".header");
    const nav = header.querySelector(".nav");
    const svg = header.querySelector(".site-logo svg");
    const textLogo = header.querySelector("svg #text");
    const pr = header.querySelector("svg #p-r");

    const headerHeight = (margin = "0px") => {
        let headerRect = header.getBoundingClientRect(),
            headerHeight = headerRect.height;

        if (margin === "0px") {
            document.body.style.setProperty(
                "--header-height",
                `${headerHeight / 2}px` 
            );
        } else {
            document.body.style.setProperty(
                "--header-height",
                `calc(${headerHeight / 2}px  + ${margin})`
            );
        }
    };

    headerHeight("2rem");

    window.onscroll = () => {
        if (
            document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50
        ) {
            header.classList.add("is-active");

            gsap.to(header, {
                yPercent: -50,
            });

            gsap.to(textLogo, {
                yPercent: 50,
            });

            gsap.to(pr, {
                xPercent: -1500,
            });

            if (window.matchMedia("(min-width: 992px)").matches) {
                gsap.to(nav, {
                    yPercent: 130,
                });

                headerHeight("2rem");

            } else {
                headerHeight("2rem");
            }
        } else {
            header.classList.remove("is-active");

            gsap.to([header, nav, svg, textLogo], {
                yPercent: 0,
            });

            gsap.to(pr, {
                xPercent: 0,
            });
        }
    };
};

export const buttonRandomPosition = () => {
    const button = document.querySelector(".compagnie");
    const rect = button.getBoundingClientRect();
    let w = window.innerWidth || document.documentElement.clientWidth;
    let h = window.innerHeight || document.documentElement.clientHeight;
    let hPx = (100 * h) / 100;
    let xMax = w - rect.width;
    let yMax = hPx - rect.height;
    let xPos = Math.floor(Math.random() * xMax);
    let yPos = Math.floor(Math.random() * yMax);

    gsap.to(button, {
        top: yPos,
        left: xPos,
        duration: 0,
        ease: "none",
    });
};