import Swiper from "swiper/swiper-bundle.min.js";

const newsOptions = {
    speed: 800,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false
    },
    loop: true,
    effect: "fade",
    preloadImages: false,
    lazy: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
            return `<span class="${className}"></span>`;
        },
    },
};

const pageOptions = {
    speed: 800,
    slidesPerView: "auto",
    loopedSlides: 3,
    centeredSlides: true,
    preloadImages: false,
    lazy: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: "auto",
        },
        992: {
            slidesPerView: "auto",
        }
    },
};

export const newsSlider = () => {
    const swiper = new Swiper(".swiper", newsOptions);
};

export const pageSlider = () => {
    const swiper = new Swiper(".swiper", pageOptions);
};
