export const readMore = () => {
	const readBtns = document.querySelectorAll(".read-button");
	for (let i = 0; i < readBtns.length; i++) {
		const readBtn = readBtns[i];
		readBtn.onclick = () => {
			let readCtn = readBtn.parentElement;
			/* display read-less button first */
			let otherBtn = readCtn.querySelector('.read-button:not(.is-active)');
			otherBtn.classList.toggle("is-active");
			/* hide active button */
			readBtn.classList.toggle("is-active");
			/* show content */
			let more = readCtn.querySelector('.more');
			more.classList.toggle("is-active");
		}
	}
}