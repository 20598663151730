import { gsap } from "gsap";
import Swup from "swup";
// import SwupDebugPlugin from "@swup/debug-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupBodyClassPlugin from "@swup/body-class-plugin";
import SwupScrollPlugin from "@swup/scroll-plugin";
import { viewportTrick } from "./components/viewport.js";
import { home } from "./components/home.js";
import {
    header,
    buttonRandomPosition,
} from "./components/header.js";
import { menu } from "./components/menu.js";
import { newsSlider, pageSlider } from "./components/slider.js";
import { player } from "./components/plyr.js";
import { readMore } from "./components/read-more.js";
import { accordion } from "./components/accordion.js";

gsap.defaults({
    ease: "expo.out",
    duration: 1,
});

const swup = new Swup({
    plugins: [
        // new SwupDebugPlugin(),
        new SwupPreloadPlugin(),
        new SwupBodyClassPlugin(),
        new SwupScrollPlugin({
            doScrollingRightAway: false,
            animateScroll: false,
        }),
    ],
});

function init() {
    viewportTrick();

    if (document.querySelector(".home-link-to-news")) {
        home();
    }

    if (document.querySelector(".header")) {
        header();
    }

    if (document.querySelector(".compagnie")) {
        buttonRandomPosition();
    }

    if (document.querySelector(".menu")) {
        menu();
    }

    if (document.querySelector(".news-container .swiper")) {
        newsSlider();
    }

    if (
        document.querySelector(".production-container .swiper") ||
        document.querySelector(".atelier-container .swiper")
    ) {
        pageSlider();
    }

    if (document.querySelector("#player")) {
        player();
    }

    if (document.querySelector(".read-more")) {
        readMore();
    }

    if (document.querySelector(".accordion")) {
        accordion();
    }
}

init();
swup.on("contentReplaced", init);