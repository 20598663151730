import { gsap } from "gsap";

const itemsAnimation = () => {
    const q = window.matchMedia("(min-width: 992px)")

    const goToNews = () => {
        const linkToNews = document.querySelector('.home-link-to-news');
        if (linkToNews) {
            window.location.href = linkToNews.href;
        } else {
            console.log('no destination');
        }
    }

    function responsiveAnimation(q) {
        const homeContainer = document.querySelector('.home-container');
        const homeItems = document.querySelectorAll(".home__item");
        const tl = gsap.timeline();

        if (q.matches) {

            for (let i = 0; i < homeItems.length; i++) {
                const homeItem = homeItems[i];
                tl.to(homeItem, {
                    opacity: 1,
                    duration: 0
                }, "+=0.8");
            }

            tl.to(homeContainer, {
                opacity: 0,
                duration: 0.8,
                ease: "none",
                onComplete: goToNews
            })

        } else {

            goToNews();

        }
    }

    responsiveAnimation(q)
    q.addEventListener("resize", responsiveAnimation)
      
};

export const home = () => {
    itemsAnimation();
};
