export const accordion = () => {
    const accordion = document.querySelector(".accordion-button");
    if (accordion) {
        accordion.onclick = () => {
            accordion.classList.toggle("is-active");
            const panel = accordion.nextElementSibling;
            panel.classList.toggle("is-active");
        };
    }
};
