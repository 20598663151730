export const menu = () => {
    const menu = document.querySelector(".menu");
    const menuBtn = document.querySelector(".nav__button");

    /* open/close menu */
    menuBtn.onclick = () => {
        const open = JSON.parse(menuBtn.getAttribute("aria-expanded"));
        menuBtn.setAttribute("aria-expanded", !open);
        menu.hidden = !menu.hidden;

        if (!menu.hidden) {
            menuBtn.classList.add('is-active')
        } else {
            menuBtn.classList.remove('is-active')
        }

        if (!menu.hidden) {
            menu.querySelector("a").focus();
        }
    };

    /* close on menu__item click */
    const menuItems = document.querySelectorAll(".menu__item");
    for (let i = 0; i < menuItems.length; i++) {
        const menuItem = menuItems[i];
        menuItem.onclick = () => {
            if (!menu.hidden) {
                const open = JSON.parse(menuBtn.getAttribute("aria-expanded"));
                menuBtn.setAttribute("aria-expanded", !open);
                menu.hidden = !menu.hidden;

                if (!menu.hidden) {
                    menuBtn.classList.add('is-active')
                } else {
                    menuBtn.classList.remove('is-active')
                }
            }
        };
    }
};
